<template>
  <common-card>
    <v-toolbar color="primary" dense dark flat>
      <v-toolbar-title>
        <span class="font-montserrat font-weight-bold">Reporte de Pólizas</span>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row class="mb-15 fill-height">
        <v-col>
          <v-row style="padding-left: 30px; margin-top: 25px"> </v-row>
          <v-row class="mb-15" style="padding-left: 30px">
            <v-col cols="3">
              <h3>Selecciona el rango de fechas</h3>
              <v-date-picker
                style="width: 100%"
                v-model="dates"
                range
                locale="es"
              ></v-date-picker>
              <h3 class="mb-2">
                Selecciona el ramos y canales para determinar la campaña
              </h3>
              <v-select
                v-model="ramo"
                filled
                dense
                multiple
                :items="ramosPermitidos"
                item-value="ramo"
                item-text="ramo"
                label="Ramo(s)"
                no-data-text="Sin datos para mostrar"
                outlined
              ></v-select>
              <v-select
                v-model="canal"
                filled
                dense
                multiple
                :items="canalesPermitidos"
                item-value="canal"
                item-text="canal"
                label="Canal(es)"
                no-data-text="Sin datos para mostrar"
                outlined
              ></v-select>
              <v-select
                v-model="producto"
                filled
                dense
                multiple
                :items="campaniasFiltradas"
                item-value="producto"
                item-text="producto"
                label="Campaña(s)"
                :no-data-text="
                  !canal || !ramo
                    ? 'Debes seleccionar canal y ramo antes'
                    : 'Sin datos para mostrar'
                "
                outlined
              ></v-select>
              <v-btn
                rounded
                color="primary"
                style="width: 100%"
                dark
                @click="getReporte({ soloUnRamo: false })"
                >Ejecutar reporte general</v-btn
              >
              <v-btn
                class="mt-5"
                rounded
                color="primary"
                style="width: 100%"
                dark
                @click="getReporte({ soloUnRamo: true })"
                :disabled="ramo.length > 1"
                >Ejecutar reporte por ramo</v-btn
              >
            </v-col>
            <v-col cols="9">
              <v-alert v-if="resultsText" class="mb-2" type="info">
                <span>{{ resultsText }}</span>
              </v-alert>

              <div>
                <div v-if="loading" style="text-align: center">
                  <h2 clas="pb-4">Cargando Reporte....</h2>
                  <br /><br />
                  <md-progress-spinner
                    :md-diameter="100"
                    :md-stroke="10"
                    md-mode="indeterminate"
                  ></md-progress-spinner>
                </div>
              </div>

              <v-card outlined>
                <v-card-title>
                  <v-text-field
                    v-model="search"
                    label="Buscar"
                    prepend-inner-icon="mdi-magnify"
                    outlined
                    dense
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <download-csv
                    :data="reportDownload"
                    name="Reporte_Emisiones.csv"
                  >
                    <v-btn
                      color="#107c41"
                      dark
                      style="position: absolute; top: 15px; right: 15px"
                    >
                      <v-icon small class="mr-2">mdi-microsoft-excel</v-icon>
                      <span>Descargar</span>
                    </v-btn>
                  </download-csv>
                </v-card-title>
                <v-card-text>
                  <v-data-table
                    :headers="columns"
                    :items="reporteData"
                    :search="search"
                    item-key="name"
                    no-data-text="Sin datos para mostrar"
                  >
                    <template v-slot:item.fechaCreacion="{ item }">
                      <span>{{
                        moment.utc(item.fechaCreacion).format("DD/MM/YYYY")
                      }}</span>
                    </template>
                    <template v-slot:item.ver="{ item }">
                      <v-btn icon small>
                        <v-icon small @click="changeRoute(item)">
                          mdi-eye
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </common-card>
</template>

<script>
import CommonCard from "@/components/commonComponents/CommonCard.vue";
import { mainAxios } from "../../../../mainAxios";

import moment from "moment-timezone";
import getStringFormaPago from "../../../../helpers/diccionarios/diccionarioFormaPago";
import getStringStatusAgente from "../../../../helpers/diccionarios/diccionarioStatusAgente";
import getPlanString from "../../../../helpers/diccionarios/diccionarioRamoAutos";
import getStatusString from "../../../../helpers/diccionarios/diccionarioPolizas";
import getEstadoString from "../../../../helpers/diccionarios/diccionarioEstadosMexico";
import getCorretDate from "../../../../helpers/diccionarios/getCorretDate";
// ADMIN FINANZAS y DIRECTOR

const columnsDownload = [
  { text: "No. Póliza", value: "poliza" },
  { text: "Póliza ID Interna", value: "id" },
  { text: "Estatus de la póliza", value: "status" },
  {
    text: "Cliente",
    value: "nombreCliente",
    valueReporteRamo: "clienteNombre",
  },
  { text: "Agente", value: "nombreAgente" },
  {
    text: "Estatus Agente",
    value: "statusAgente",
    requiereDiccionario: true,
    diccionarioMethod: getStringStatusAgente,
  },
  { text: "Canal", value: "canal" },
  { text: "Ramo", value: "nombreRamo" },
  {
    text: "Aseguradora",
    value: "nombreAseguradora",
    valueReporteRamo: "nombreEmpresa",
  },
  { text: "Producto", value: "nombreProducto" },
  {
    text: "Nueva / Renovación",
    value: "nuevaRenovacion",
    valueReporteRamo: "tipoPoliza",
  },
  {
    text: "Inicio Vigencia",
    value: "fechaInicioVigencia",
    valueReporteRamo: "inicio",
  },
  {
    text: "Fin Vigencia",
    value: "fechaFinVigencia",
    valueReporteRamo: "fin",
  },
  { text: "Periodicidad de Pago", value: "periodicidad" },
  {
    text: "Número de cuotas",
    value: "numCuotas",
    valueReporteRamo: "cuotas",
  },
  { text: "Prima Total", value: "primaTotal" },
  { text: "Prima Neta", value: "primaNeta" },
  { text: "Primer Pago", value: "primerPago" },
  {
    text: "Subsecuente",
    value: "subsecuente",
    valueReporteRamo: "subsecuentePago",
  },
  { text: "Años", value: "anios" },
  {
    text: "Forma de pago",
    value: "formaPago",
    requiereDiccionario: true,
    diccionarioMethod: getStringFormaPago,
  },
  {
    text: "Fecha de creación",
    value: "fechaCreacion",
    valueReporteRamo: "fecha",
    diccionarioMethod: getCorretDate,
  },
  { text: "Fecha de Emisión", value: "fechaEmision" },
  { text: "Fecha Primer Pago", value: "fechaPrimerPago" },
  {
    text: "Ejecutivo",
    value: "nombreEjecutivo",
    valueReporteRamo: "ejecutivoNombre",
  },
  { text: "Campaña", value: "campania" },
  {
    text: "Gerente Comercial",
    value: "nombreComercialGeneral",
    valueReporteRamo: "nombreComercial",
  },
  { text: "Fecha de cancelación", value: "fechaCancelacion" },
  {
    text: "Motivo Cancelación/Finalización",
    value: "motivoCancelacion",
  },
  { text: "Renueva a", value: "polizaAnterior" },
];

const columnsDownloadRamo = [
  { text: "No. Póliza", value: "poliza" },
  { text: "Póliza ID Interna", value: "id" },
  {
    text: "Estatus de la póliza",
    value: "status",
    requiereDiccionario: true,
    diccionarioMethod: getStatusString,
  },
  {
    text: "Cliente",
    value: "nombreCliente",
    valueReporteRamo: "clienteNombre",
  },
  { text: "Agente", value: "nombreAgente" },
  { text: "Canal", value: "canal" },
  {
    text: "Aseguradora",
    value: "nombreAseguradora",
    valueReporteRamo: "aseguradoraNombre",
  },
  { text: "Producto", value: "nombreProducto" },
  {
    text: "Nueva / Renovación",
    value: "nuevaRenovacion",
    valueReporteRamo: "tipoPoliza",
  },
  {
    text: "Inicio Vigencia",
    value: "fechaInicioVigencia",
    valueReporteRamo: "inicio",
  },
  {
    text: "Fin Vigencia",
    value: "fechaFinVigencia",
    valueReporteRamo: "fin",
  },
  { text: "Ramo", value: "nombreRamo" },
  { text: "Periodicidad de Pago", value: "periodicidad" },
  {
    text: "Número de cuotas",
    value: "numCuotas",
    valueReporteRamo: "cuotas",
  },
  { text: "Prima Total", value: "total" },
  { text: "Prima Neta", value: "primaNeta" },
  { text: "Primer Pago", value: "primerPago" },
  {
    text: "Subsecuente",
    value: "subsecuente",
    valueReporteRamo: "subsecuentePago",
  },
  { text: "Años", value: "anios" },
  {
    text: "Forma de pago",
    value: "formaPago",
    requiereDiccionario: true,
    diccionarioMethod: getStringFormaPago,
  },
  {
    text: "Fecha de creación",
    value: "fechaCreacion",
    valueReporteRamo: "fecha",
  },
  { text: "Fecha de Emisión", value: "fechaEmision" },
  { text: "Fecha Primer Pago", value: "fechaPrimerPago" },
  {
    text: "Ejecutivo",
    value: "nombreEjecutivo",
    valueReporteRamo: "ejecutivoNombre",
  },
  { text: "Campaña", value: "campania" },
  {
    text: "Gerente Comercial",
    value: "nombreComercialGeneral",
    valueReporteRamo: "nombreComercial",
  },
  { text: "Fecha de cancelación", value: "fechaCancelacion" },
  {
    text: "Motivo Cancelación",
    value: "motivoCancelacion",
  },
  { text: "Renueva a", value: "polizaAnterior" },
  {
    text: "Renovacion",
    value: "checkPolizaRenovada",
    requiereDiccionario: true,
    diccionarioMethod: (v) =>
      ({ true: "SI", false: "NO" }[v] || "No disponible"),
  },
  { text: "Fecha Finalización", value: "fechaFinalizacion" },
  { text: "Motivo Finalización", value: "motivoFinalizacion" },
  { text: "Lead", value: "lead_id" },
  { text: "Tipo Persona", value: "tipoPersona" },
  { text: "RFC", value: "rfc" },
  { text: "Razón Social", value: "razon_socialCliente" },
  { text: "Nombre", value: "nombre" },
  { text: "Apellido Paterno", value: "apellido_paterno" },
  { text: "Apellido Materno", value: "apellido_materno" },
  { text: "Código Postal", value: "codigoPostal" },
  { text: "Fecha Nacimiento", value: "fechaNacimiento" },
  { text: "Género", value: "genero" },
  {
    text: "Estado de la República",
    value: "estado_id",
    requiereDiccionario: true,
    diccionarioMethod: getEstadoString,
  },
  { text: "Teléfono de contacto", value: "telefono" },
  { text: "Correo de contacto", value: "correo_electronico" },
  { text: "Origen", value: "origen" },
];

export default {
  components: {
    CommonCard,
  },

  data() {
    return {
      moment,
      dates: ["", ""],
      roles: JSON.parse(localStorage.getItem("roles")),
      agenteUuid: localStorage.getItem("agenteUuid"),
      loading: false,
      bloquear: true,
      reporteData: [],
      reportDownload: [],
      campanias: [],
      canales: [],
      ramos: [],
      usuario: null,
      canal: "",
      ramo: "",
      producto: "",
      resultsText: "",
      search: "",

      columns: [
        { text: "Póliza", value: "id" },
        {
          text: "Cliente",
          value: "nombreCliente",
          valueReporteRamo: "clienteNombre",
        },
        {
          text: "Producto",
          value: "producto",
          valueReporteRamo: "nombreProducto",
        },
        {
          text: "Agente",
          value: "nombreAgente",
          valueReporteRamo: "nombreAgente",
        },
        { text: "Campaña", value: "campania" },
        { text: "Fecha", value: "fechaCreacion", valueReporteRamo: "fecha" },
        { text: "Ver", value: "ver" },
      ],

      columnsRamo: {
        AUTOS: [
          {
            text: "Plan",
            value: "plan_id",
            requiereDiccionario: true,
            diccionarioMethod: getPlanString,
          },
          { text: "Modelo", value: "modelo" },
          { text: "Marca", value: "marca" },
          { text: "Submarca", value: "submarca" },
          { text: "Version", value: "version" },
          { text: "Detalle Auto", value: "detalle_auto" },
          { text: "Origen Auto", value: "origen_auto" },
          { text: "Uso Auto", value: "uso_auto" },
          { text: "Número de Motor", value: "num_motor" },
          { text: "Número de Serie", value: "num_serie" },
          { text: "Placas", value: "placas" },
        ],
      },
    };
  },

  computed: {
    puedeVerTodo() {
      const roles = JSON.parse(localStorage.roles);
      if (!roles) return false;

      const rolesPermitidos = ["ADMIN", "FINANZAS", "DIRECTOR"];
      let result = false;
      rolesPermitidos.forEach((e) => {
        if (roles.find((rol) => rol.rol === e)) result = true;
      });

      return result;
    },

    canalesPermitidos() {
      if (this.puedeVerTodo) return this.canales;
      if (!this.usuario || !this.canales.length) return [];
      const result = [];

      this.usuario.canales.forEach((canalUsuario) => {
        result.push(this.canales.find((e) => e.id == canalUsuario));
      });

      return result;
    },

    ramosPermitidos() {
      if (this.puedeVerTodo) return this.ramos;
      if (!this.usuario || !this.ramos.length) return [];
      const result = [];

      this.usuario.ramos.forEach((ramoUsuario) => {
        result.push(this.ramos.find((e) => e.id == ramoUsuario));
      });

      return result;
    },

    campaniasFiltradas() {
      if (!this.canal || !this.ramo) return [];

      let result = [];
      result = this.campanias.filter((e) => {
        const coincideCanal = this.canal.find((j) => j == e.canal);
        const coincideRamo = this.ramo.find((j) => j == e.ramo);

        return coincideCanal && coincideRamo;
      });

      result = result.filter((e, index) => {
        return result.findIndex((z) => z.producto == e.producto) == index;
      });

      return result;
    },

    soloUnRamo() {
      return this.ramo && this.ramo.length === 1;
    },
  },

  mounted() {
    this.getCampanias();
    this.getRamos();
    this.getCanales();
    this.getUsuario();
  },

  methods: {
    getCampanias() {
      this.loading = true;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios
        .get("/v1/campania/lead-segurify/list", config)
        .then(({ data }) => {
          this.campanias = data;
          this.loading = false;
        });
    },

    getCanales() {
      this.loading = true;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios.get("/v1/canal/list", config).then(({ data }) => {
        this.canales = data;
        this.loading = false;
      });
    },

    getRamos() {
      this.loading = true;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios.get("/v1/ramo/list", config).then(({ data }) => {
        this.ramos = data;
        this.loading = false;
      });
    },

    getUsuario() {
      this.loading = true;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios
        .get(`/v1/agente/uuid/${localStorage.getItem("agenteUuid")}`, config)
        .then(({ data }) => {
          this.usuario = data;
          this.loading = false;
        });
    },

    getReporte({ soloUnRamo }) {
      this.loading = true;
      this.resultsText = "";
      this.reportDownload = [];
      this.reporteData = [];
      const canalesIds = this.canales
        .filter((canal) => this.canal.find((e) => e == canal.canal))
        .map((e) => e.id);
      const reporteUrl = soloUnRamo
        ? `v1/reporte/emisiones_ramo`
        : `/v1/reporte/emision/canales`;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      if (soloUnRamo) {
        this.columnsDownload = columnsDownloadRamo;
        this.populateRamo();
      } else this.columnsDownload = columnsDownload;

      let inicioUTC = null;
      let finUTC = null;

      if (this.dates.length > 0) {
        if (this.dates[1]) {
          inicioUTC = moment(this.dates[0])
            .tz("America/Mexico_City", true)
            .tz("UTC")
            .format("YYYY-MM-DD HH:mm:ss");
          finUTC = moment(this.dates[1])
            .tz("America/Mexico_City", true)
            .tz("UTC")
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
        } else {
          inicioUTC = moment(this.dates[0])
            .tz("America/Mexico_City", true)
            .tz("UTC")
            .format("YYYY-MM-DD HH:mm:ss");
          finUTC = moment(this.dates[0])
            .tz("America/Mexico_City", true)
            .tz("UTC")
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
        }
      }

      const body = soloUnRamo
        ? {
            fechas: [inicioUTC, finUTC],
            ramo: this.ramo[0],
          }
        : { fechaInicio: inicioUTC, fechaFin: finUTC, canales: canalesIds };

      mainAxios.post(reporteUrl, body, config).then(({ data }) => {
        data.forEach(async (emision) => {
          const emisionFull = {
            ...(emision.requestJson && { ...JSON.parse(emision.requestJson) }),
            ...emision,
            ...(soloUnRamo && { campania: emision.campaniaN }),
          };
          let payloadTable = {};
          let payloadDownload = {};

          const coincideRamo = !!this.ramos.find(
            (e) => e.ramo == emisionFull.nombreRamo
          );
          const coincideCanal = !!this.canales.find(
            (e) => e.canal == emisionFull.canal
          );
          const coincideCampania = !!this.producto.find(
            (e) => e == emisionFull.campania
          );

          const limitarRamo = !this.soloUnRamo;
          const limitarCampania = this.producto && this.producto.length;

          if (
            (limitarRamo && !coincideRamo) ||
            !coincideCanal ||
            (limitarCampania && !coincideCampania)
          )
            return;

          this.columns.forEach((informeColumn) => {
            payloadTable = {
              ...payloadTable,
              ...this.getPayloadProperty({
                nombreColumna: informeColumn.value,
                value: emisionFull[informeColumn.value],
                valueReporteRamo: emisionFull[informeColumn.valueReporteRamo],
              }),
            };
          });

          this.columnsDownload.forEach((informeColumn) => {
            payloadDownload = {
              ...payloadDownload,
              ...this.getPayloadProperty({
                nombreColumna: informeColumn.text,
                value: emisionFull[informeColumn.value],
                valueReporteRamo: emisionFull[informeColumn.valueReporteRamo],
                fullColumn: informeColumn,
              }),
            };
          });

          this.reporteData.push(payloadTable);
          this.reportDownload.push(payloadDownload);
        });
      });

      this.bloquear = false;
      this.loading = false;
      this.resultsText = soloUnRamo
        ? `Resultados de reporte de ramo ${this.ramo[0]}`
        : `Mostrando reporte general`;
    },

    changeRoute(item) {
      this.$router.push(`/emision/${item.id}`);
    },

    populateRamo() {
      this.columnsDownload = [
        ...this.columnsDownload,
        ...this.columnsRamo[this.ramo[0]],
      ];
    },

    esCampoValido(value) {
      return value || value == 0;
    },

    getPayloadProperty({ nombreColumna, value, valueReporteRamo, fullColumn }) {
      let payload = {};
      if (this.esCampoValido(value) || this.esCampoValido(valueReporteRamo)) {
        if (fullColumn && fullColumn.requiereDiccionario)
          payload[nombreColumna] = fullColumn.diccionarioMethod(value);
        else
          payload[nombreColumna] = this.soloUnRamo
            ? valueReporteRamo || value
            : value;
      } else payload[nombreColumna] = "No disponible";

      return payload;
    },
  },
};
</script>
